/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import cx from 'classnames';
import {
  Typography, InputLabel,
  Card, Button,
  Modal, TextField, Select,
  FormControl,
} from '@material-ui/core';
import Glossier from '../../assets/customers/glossier.svg';
import Doordash from '../../assets/customers/doordash.svg';
import Intercom from '../../assets/customers/intercom.svg';
import Reddit from '../../assets/customers/reddit.svg';
import Turo from '../../assets/customers/turo.svg';

import styles from './WinEveryCandidate.module.scss';
import Layout from '../../components/Layout';

const logos = [
  <Glossier />,
  <Doordash />,
  <Intercom />,
  <Reddit />,
  <Turo />,
];

const possibleRoles = [
  'Sourcer/Recruiter',
  'Recruiting Coordinator',
  'Hiring Manager',
  'Head of Talent/Head of People ',
  'CxO',
  'Other',
];
const possibleAts = [
  'Bullhorn',
  'Greenhouse',
  'Lever',
  'Other',
  'None',
];

const WinEveryCandidate = ({
  location,
}) => {
  const [open, setOpen] = useState(false);
  const [ats, setAts] = useState();
  const [email, setEmail] = useState();

  const featuredQuote = {
    quote: 'This thing is absolute magic.',
    attribution: 'Jesse Harrison, Recruiting Manager',
  };

  return (
    <Layout
      location={location}
      showHeader={false}
      showFooter={false}
      disableContainer
    >
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <img
            className={styles.logo}
            src="/images/brand/white/logo.svg"
            alt="Resource Horizontal Logo"
          />
          <h1>Win every candidate.</h1>
          <h2>Learn how the fastest-growing companies in the world are winning the talent war with Resource.</h2>
          <div className={styles.form}>
            <input
              type="text"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(true)}
            >Get Started
            </Button>
          </div>
          <p>Experience Resource for free with as many team members as you like!</p>
          <div className={styles.imageDiv}>
            <img 
              className={styles.bannerImage}
              src="/images/uploads/masthead.png"
              alt="Infographic of candidate being reached out to virtually"
            />
          </div>
        </div>
        <section className={cx('customers', 'pb-0')}>
          <div className={cx('container', 'p-0')} style={{ textAlign: 'center' }}>
            <div className={cx('inline-container', 'jc-center', 'mb-1')}>
              { logos.map((logo) => <div key={`c${logo}`}>{logo}</div>)}
            </div>
            <h1 className="fw-normal lato mb-1">&ldquo;{featuredQuote.quote}&rdquo;</h1>
            <p>{featuredQuote.attribution}</p>
          </div>
        </section>
        <Modal
          className={styles.modalCont}
          aria-labelledby="Get Started Form"
          aria-describedby="Fill out this form to get started with resource"
          open={open}
          onClose={() => setOpen(false)}
        >
          <Card
            className={styles.card}
          >
            <Typography variant="h4">
              Learn more
            </Typography>
            <form action="https://go.pardot.com/l/823443/2020-01-09/vvs" method="post">
              <div className={styles.row}>
                <TextField
                  required
                  className={styles.input}
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                />
                <TextField
                  required
                  className={styles.input}
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                />
              </div>
              <div className={styles.row}>
                <TextField
                  required
                  className={styles.input}
                  name="cell"
                  label="Cell"
                  type="tel"
                  placeholder="3603452345"
                  variant="outlined"
                />
                <TextField
                  required
                  className={styles.input}
                  name="companyName"
                  label="Company Name"
                  variant="outlined"
                />
              </div>
              <div className={styles.row}>
                <TextField
                  required
                  className={styles.input}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                />
              </div>
              <div className={styles.row}>
                <FormControl
                  className={styles.selectCont}
                  variant="outlined"
                  required
                >
                  <InputLabel
                    className={styles.selectLabel}
                    id="role-label"
                  >
                      Title
                  </InputLabel>
                  <Select
                    native
                    name="role"
                    labelId="role-label"
                  >
                    <option value="" />
                    {possibleRoles.map((role) => (
                      <option value={role}>
                        {role}
                      </option>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles.row}>
                <FormControl
                  className={styles.selectCont}
                  variant="outlined"
                  required
                >
                  <InputLabel
                    className={styles.selectLabel}
                    id="ats-label"
                  >
                      ATS
                  </InputLabel>
                  <Select
                    native
                    name="ats"
                    labelId="ats-label"
                    value={ats}
                    onChange={(event) => setAts(event.target.value)}
                  >
                    <option value="" />
                    {possibleAts.map((atsName) => (
                      <option value={atsName}>
                        {atsName}
                      </option>
                      ))}
                  </Select>
                </FormControl>
              </div>
              {ats === 'Other' && (
              <div className={styles.row}>
                <TextField
                  className={styles.input}
                  type="text"
                  name="otherAts"
                  label="ATS Name"
                  variant="outlined"
                />
              </div>
                )}
              <Button
                type="submit"
                variant="contained"
                color="secondary"
              >
                  Submit
              </Button>
            </form>
          </Card>
        </Modal>
        <script type="text/javascript">
          {`
            piAId = '824443';
            piCId = '3669';
            piHostname = 'pi.pardot.com';

            (function() {
              function async_load(){
                var s = document.createElement('script'); s.type = 'text/javascript';
                s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
              }
              if(window.attachEvent) { window.attachEvent('onload', async_load); }
              else { window.addEventListener('load', async_load, false); }
            })();
          `}
        </script>
      </div>
    </Layout>
  );
}

export default WinEveryCandidate;
